nav {
    position: fixed;
    right: 0;
    top: 0;
    background: white;
    height: 100vh;
    width: 80%;
    z-index: 999;
    text-transform: uppercase;
    -webkit-transform: translateX(100%);
    transform: translateX(150%);
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin-top: 8em;
}

nav ul a {
    color: black;
    padding: 0.75em 2em;
    display: block;
}

nav ul a:hover {
    background-color: #bdbdbd;
}

nav .close {
    float: right;
    margin: 2em;
    width: 2.5em;
}

.open-nav {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    width: 90vw;
    background: black;
}

header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}


@media only screen and (min-width: 1201px) {
    .menu {
        display: none;
    }

    nav {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        position: unset;
        display: block;
        width: auto;
        height: auto;
        background: none;
    }

    .close {
        display: none;
    }

    nav ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0;
    }

    nav ul a {
        color: white;
        padding: 0.5em 1.5em;
        font-size: 0.9rem;
    }

    nav ul a:hover {
        background: none;
        text-decoration: underline;

    }
}