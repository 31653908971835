body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1.5s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}